

.c-product-images {

  .c-product-images__image {
    border: 1px solid $color-border-light;

    &.is-zoomable {
      cursor: zoom-in;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-product-images__thumbs {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto hidden;
    gap: $space-s;
    margin-top: $space-s;
    justify-content: center;

    .c-product-images__thumbs__item {
      display: block;
      border: 1px solid $color-border-light;

      &.is-selected {
        border: 1px solid $color-accent;
      }
    }

    img {
      display: block;
    }
  }
}