.bpl {

  &__header {
    margin-bottom: $space-m;
  }

  &__footer {
    margin-top: $space-l;
    border-top: 1px solid $color-border;
    padding-top: $space-l;
  }



}


.bpl_search {

  margin: $space-l 0;

  .bpl_search_text{
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 4px $space-xs;
    background-color: $color-surface-dark;
    color: $color-text-on-surface-dark;
    align-items: center;

    a svg {
      font-size: 24px;
      border-left: 1px solid #aaa;
      margin-left: 8px;
      padding-left: 4px;
    }
  }

}

.bpl-filters {
  background-color: #fafafa;
  padding: 1rem 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: $space-m;

  .bpl-filters__toolbar {
    padding: $space-xs;
  }

  .bpl-filters__toolbar-label {
    font-weight: $fontweight-bold;
  }

  .bpl-filters__row {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    background-color: #fafafa;
  }

  .bpl-filters__f {
    border: 1px solid $color-border-light;
    margin: 0.5rem 0.25rem;
    font-size: 14px;
    padding: 0.5rem;
    width: 14rem;
    background-color: #fff;

    flex-shrink: 0;
    flex-grow: 0;

    .bpl-filters__f-search {
      border: 1px solid $color-border;
      width: 100%;
      /* height: 1.5rem; */
      font-weight: 400;
      padding: 4px;
      line-height: 1.4em;
      margin-top: 8px;
      font-size: 12px;
    }

    .bpl-filters__f-name {
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .bpl-filters__f-select {
      background-color: #fff;
      max-height: 12rem;
      overflow: auto;

      .bpl-filters__f-label {
        padding: 0.3rem 0.5rem 0.3rem 0;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }

        input[type=checkbox] {
          appearance: revert;
        }

        .bpl-filters__f-label-n {
          width: calc(100% - 1rem);
          display: flex;
          font-size: 0.8125rem;
          gap: 0.25rem;

          .bpl-filters__f-label-nt {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;

            overflow: hidden;
            word-break: break-all;
          }

          .bpl-filters__f-label-nc {

          }
        }
      }

      label {
        padding: 0.5rem;

      }

    }
  }
}

.bpl-filters__footer {
  display: flex;
  gap: $space-m;
}

.bll-filters__clear {
  margin-top: $space-m;
  display: inline-flex;
  align-items: center;
  color: $color-accent-hover;
  border: 1px solid $color-accent-hover;
  background-color: #fff;
  padding: 4px 8px 4px 4px;
  cursor: pointer;
  font-size: $fontsize-xs;

  & > span {
    display: block;
    line-height: 1;
  }

  &:hover {
    background-color: rgba(255,255,255,.3);
  }
  svg {
    display: block;
    font-size: 18px;
    margin-right: 4px;
  }

}

.bpl-filters__p {
  margin-top: $space-m;
  display: inline-flex;
  margin-right: $space-xl;
  flex-wrap: nowrap;
  font-size: $fontsize-xs;
  align-items: center;
  gap: $space-s;

  &-name {
    font-weight: $fontweight-bold;
  }

  &-item {
    display: inline-flex;
    align-items: center;
    background-color: $color-surface-dark;
    color: $color-text-on-surface-dark;
    padding: 4px 4px 4px 8px;

    button {
      margin-left: 4px;
      cursor: pointer;
      font-size: 18px;
      display: block;
      &:hover {
        background-color: rgba(255,255,255,.3);
      }
      svg {
        display: block;
      }
    }
  }
}

.empty-bpl {
  svg {
    font-size: 92px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #ddd;
  }

  .empty-bpl__header {
    text-align: center;
    font-size: 24px;
    color: #666;
  }

}