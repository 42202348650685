.header-nav__tax-drop {

  .icon {
    color: #fff !important;
  }

  &:hover {
    .header-nav__tax-drop-link {
      color: #fff !important;
      background-color: $color-accent-hover !important;
    }
  }

  .header-nav__tax-drop-link {
    color: #fff;
    background-color: $color-accent;
  }
}

.nav-drop {
  position: relative;

  &__drop {
    z-index: 500;
    visibility: hidden;
    display: none;
    position: absolute;
    min-width: 100%;
  }

  &:hover {
    .nav-drop__drop {
      display: block;
      visibility: visible;
      margin-top: -1px;
    }
  }
}

