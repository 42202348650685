$section--beetween-gap:  $space-row-gap;

@mixin section--title(){
  position: relative;

  font-size: $fontsize-xl;
  font-weight: $fontweight-bold;
  margin-bottom: $space-m;

  @include media-breakpoint-up('md') {
    font-size: $fontsize-xxl;
    font-weight: $fontweight-strong;
    margin-bottom: $space-xl;
  }

  &-tagline{
    font-size: $fontsize-m;
    font-weight: $fontweight-strong;
    text-transform: uppercase;
    margin-bottom: 32px;

    & + .section__title-t {
      &:after {
        bottom: unset;
        top: -4px;
      }
    }
  }


  &:after {
    content: '';
    position: absolute;
    background-color: $color-accent;
    left:0;
    bottom: -8px;
    height: 2px;
    width: 45px;
  }

  @include media-breakpoint-up('md'){
    &:after {
      bottom: -16px;
      height: var(--title-accent-h, 8px);
      width: 45px;
    }

    &.section__title--with-tagline {
      &:after {
        bottom: unset;
        top: 32px;
      }
    }
  }

  &.section__title--with-tagline {
    &:after {
      bottom: unset;
      top: 28px;
    }
  }

}

.section {
  padding-top: $space-row-gap;

  &__title {
    @include section--title();

    &--small {
        --title-accent-h: 3px;
        font-size: $fontsize-l;
        font-weight: $fontweight-strong;
        margin-bottom: $space-s;

    }
  }


}
