a.box:hover {
  box-shadow: 0px 0px 8px 0 rgba($color-border-strong, .5);
  .box__top-title {
    &:after {
      width: 70%;
    }
  }
}

.box {
  font-family: $fontfamily-condensed;
  height: 100%;
  min-height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  transition: all 0.2s ease-in-out;

  &__body {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 100;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .box__top-title {
    font-size: $fontsize-s;
    padding: $space-xxl $space-xxl $space-xs $space-xxl;
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: -2px;
      width: 30%;
      max-width: 60%;
      background-color: $color-accent;
      height: 2px;
      transition: all 0.4s ease-in-out;
    }
  }

  .box__title {
    font-size: $fontsize-xxl;
    font-weight: $fontweight-bold;
    padding: $space-xl $space-xxl 0 $space-xxl;
  }

  .box__description {
    padding: $space-xl $space-xxl $space-xs;
    font-size: $fontsize-m;
    line-height: $lineheight-xl;
    width: 70%;
    min-width: 340px;
  }

  .box__footer {
    flex-grow: 1;
    display: flex;
    padding: $space-m $space-xxl $space-xxl $space-xxl;
  }

  &__body {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 100;
    color: #fff;
  }

  &__bg-wrap {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__bg {
    display: block;
    object-fit: cover;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: linear-gradient(90deg,rgba(0,0,0,.8) 5%,transparent 80%);
  }
}