.container {
  width: 100%;
  padding-left: $bootstrap--container-horizonal-padd--space;
  padding-right: $bootstrap--container-horizonal-padd--space;
  margin-left: auto;
  margin-right: auto;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.container-narrow {
  width: 100%;
  padding-left: $bootstrap--container-horizonal-padd--space;
  padding-right: $bootstrap--container-horizonal-padd--space;
  margin-left: auto;
  margin-right: auto;
}


@include media-breakpoint-up('xl'){
  .container {
    max-width: 1140px;
    padding-left: 0;
    padding-right: 0;
  }
}

@include media-breakpoint-up('xxl'){
  .container {
    max-width: 1320px;
    padding-left: 0;
    padding-right: 0;
  }
}

