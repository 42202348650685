@import "blocks/p-meta";
@import "blocks/p-short-desc";
@import "blocks/p-text-block";
@import "blocks/p-gallery";
@import "blocks/p-images";

.product-section {
  margin-top: $space-l;
}

.p-name-sticky-col {
  display: none;
}

.p__video {
  background-color: $color-surface-light;
  padding: $space-m;
  margin-top: $space-l;
  margin-bottom: $space-l;

  .p__video-title {
    font-size: 18px;
    font-weight: $fontweight-bold;
    line-height: $lineheight-l;
  }

  .p__video-p {
    font-size: 14px;
    margin-top: 4px;
  }

  .p__video-thumb {
    position: relative;
    margin-top: $space-m;
    cursor: pointer;

    svg {
      transition: all .2s ease-in-out;
      position: absolute;
      width: 64px;
      height: 64px;
      top: calc(50% - 32px);
      left: calc(50% - 32px);
      opacity: .4;
    }

    &:hover {
      svg {
        opacity: .8;
      }
    }

  }


}

.p__cat {
  padding: $space-xl;
  background-color: $color-surface-highlight;

  .p__cat-t {
    display: flex;
    flex-wrap: nowrap;
    font-size: $fontsize-l;
    font-weight: $fontweight-strong;
    align-items: center;
  }

  .p__cat-tt {
    flex-grow: 1;
  }

  .p__cat--ti {
    width: 20%;

    img {
      max-width: 100%;
      max-height: 48px;
    }
  }

  .p__cat-d {
    margin-top: $space-l;
    font-size: $fontsize-s;
    line-height: $lineheight-l;
  }

  .p__cat-b {
    margin-top: $space-l;
  }

}

.p__order {
  padding: $space-xl;
  background-color: $color-surface-highlight;

  .p__order-t {
    font-size: 18px;
    line-height: $lineheight-l;
    font-weight: $fontweight-bold;
    margin-bottom: $space-xl;
  }

  .p__order-d {
    font-size: $fontsize-m;
    line-height: $lineheight-l;
    font-weight: $fontweight-base;
    margin-bottom: $space-xl;
  }


  .p__order__footer-avl {
    display: block;
    text-align: center;
    margin-top: $space-m;
    text-transform: uppercase;
    font-size: $fontsize-s;
    font-weight: $fontweight-bold;
  }
}

.p__label {
  margin-top: 0;
  margin-bottom: $space-s;
  font-size: $fontsize-l;
  font-weight: $fontweight-strong;
}

.p__short-desc {
  font-size: $fontsize-s;
}

.p__meta {
  margin-top: $space-s;
  margin-bottom: $space-l;
  font-size: $fontsize-xs;
}



@include media-breakpoint-up('md') {
  .p__label {
    font-size: $fontsize-xl;
    margin-bottom: $space-xl;
  }

  .p__imgwrap {
    margin-top: 0;
  }

  .p__meta {
    margin-top: $space-m;

  }
}


.p__name-sticky-col {
  display: none;
}

.p__footer {
  .p__footer__footer-avl {
    flex-grow: 1;
    background-color: $color-surface-middle-dark;
    color: $color-text-on-surface-middle-dark;
    padding: $space-s $space-m;
    text-align: center;

    &--available {
      background-color: $color-surface-dark;
      color: $color-text-on-surface-dark;
    }
  }
}


.p__table {
  width: 100%;
  font-size: $fontsize-s;
  border-collapse: collapse;

  caption {
    font-size: $fontsize-s;
    font-weight: $fontweight-bold;
    background-color: $color-surface-highlight;
    color: $color-text-on-surface-highlight-dark;
    padding: $space-s $space-s;
    margin-bottom: $space-xxs;
  }

  th, td {
    padding: $space-xs $space-s;
  }

  tr:nth-child(odd) {
    background-color: $color-surface-highlight;
  }

  .p__table__v {
    font-weight: $fontweight-bold;
  }
}


.p__name-sticky-col {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 0.5rem;
  margin-bottom: 2rem;
  z-index: 2;
}


.product-header__title {
  padding-top: $space-s;
  padding-bottom: $space-xl;
  border-bottom: 2px solid $color-border;

  .content-header__h {
    margin-top: 0;
    flex-grow: 1;
    margin-bottom: $space-s;
  }

  .button {
    display: block;
    width: 100%;
  }
}

@include media-breakpoint-up('md') {

  .product-header__title {
    padding-top: $space-s;
    padding-bottom: $space-s;
    border-bottom: 2px solid $color-border;
    display: flex;
    align-items: center;

    .content-header__h {
      margin-top: 0;
      flex-grow: 1;
      margin-bottom: 0;
    }


    .button {
      display: inline-block;
      width: auto;
    }
  }


}


.p__name {

  h1 {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
    padding-bottom: $space-xl;
    border-bottom: 1px solid $color-border;
  }

  .p__name__btn {
    margin-top: $space-xs;
  }
}


@include media-breakpoint-up('md') {
  .p__imgwrap {
    width: 100%;

    img {
      max-height: none;
    }
  }
  .p__name {
    h1 {
      font-size: 1.8rem;
    }

    .p__name__btn {
      float: right;
      margin-top: -6px;
    }
  }
}


.sticky-sidebar {
  position: sticky;
  top: 2rem;
  align-self: flex-start;
}

.p__contact {
  border: 1px solid $color-border;
  padding: $space-xl;


  .p__contact__head {
    font-weight: 700;
    font-size: $fontsize-m;
  }

  .p__contact__text {
    font-weight: 400;
    color: $color-text-light;
    font-size: $fontsize-s;
    margin-top: $space-m;
    margin-bottom: $space-l;
  }

}

$tabs-offset: 70px;

.tabs {

  --offset: #{$tabs-offset};

  .tabs__content {
    margin-bottom: $space-l;
  }

  .tabs__links {
    margin-top: calc(-1 * var(--offset));
    padding-top: var(--offset);

    display: flex;
    font-size: $fontsize-m;
    gap: $space-xl;
    font-weight: 500;
    border-bottom: 1px solid $color-border;


    a {
      color: $color-text-light;
      display: block;
      padding: $space-m 0;
      position: relative;
      top: 1px;

      &.selected {
        color: $color-accent-hover;
        border-bottom: 2px solid $color-accent;
      }
    }
  }
}


.tabs__text + .tabs__text {
  h2 {
    margin-top: $space-xl;
  }
}

.tabs__text {
  margin-top: $space-m;

  h2 {
    padding-top: $space-xl;
    border-top: 1px solid $color-border;
    margin-bottom: $space-l;
    font-size: $fontsize-m;
    font-weight: 600;
  }
}

.p__params {
  border: 1px solid $color-border;
  padding: $space-xl;


  dt {
    font-size: $fontsize-xs;
    color: $color-text-light;
    margin-bottom: $space-xs;
  }

  dd {
    padding-bottom: $space-l;
    margin-bottom: $space-l;
    border-bottom: 1px solid $color-border;
  }

  dd:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}


.p__files {
  margin-top: auto;
  display: grid;
  gap: $space-s;
  grid-template-columns: repeat(1, 1fr);

  .p__files__head {
    font-weight: 700;
    font-size: $fontsize-m;
    border-bottom: 1px solid $color-border;
    margin-bottom: $space-l;

    span {
      border-bottom: 2px solid $color-accent;
      padding-bottom: $space-m;
      top: 1px;
      display: inline-block;
      position: relative;
    }
  }

  a {
    display: flex;
    align-items: center;

    svg {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-bottom: 4px;
    }

    span {
      display: block;
    }
  }

}


@include media-breakpoint-up('md') {
  .p__files {
    grid-template-columns: repeat(2, 1fr);
  }
}


.p__cats {
  margin-top: $space-xl;

  .p__cats__head {
    font-weight: 700;
    font-size: $fontsize-m;
    border-bottom: 1px solid $color-border;
    margin-bottom: $space-l;

    span {
      border-bottom: 2px solid $color-accent;
      padding-bottom: $space-m;
      top: 1px;
      display: inline-block;
      position: relative;
    }
  }

  .p__cats__tax-section {
    & + .p__cats__tax-section {
      margin-top: $space-m;
      border-top: 1px solid $color-border;
      padding-top: $space-m;
    }

    & > div {
      margin-bottom: $space-xs;
    }
  }

  .p__cats__tax-head {
    font-size: $fontsize-base;
    font-weight: 500;
    padding: $space-xs 0;
  }

  a {
    font-size: $fontsize-s;
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
    }
  }

}
