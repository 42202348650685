.banner {
  background-repeat: no-repeat;
  height: 100%;
  background-size: contain;
  display: flex;
  align-items: flex-start;
  gap: $space-m;
  justify-content: center;
  flex-direction: column;

  background-color: #fff;
  background-position: right bottom;

  &__title {
    font-weight: $fontweight-bold;
    line-height: $lineheight-base;
  }

  .banner__title + .banner__text {
    margin-top: $space-s;
  }

  &.banner--textsmall {
    font-size: $fontsize-m;
    .banner__title {
      font-size: $fontsize-l;
    }
  }

  &.banner--textlarge {
    line-height: $lineheight-l;
    font-size: $fontsize-xl;
    .banner__title {
      font-size: $fontsize-xl;
    }
  }


}