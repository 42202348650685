


.subcategories {

  &__sitem, &__item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $color-border-light;
    border-bottom: 1px solid $color-border-light;
    margin-top: -1px;
    align-items: center;
  }

  &__item-img {
    width: 20%;
    padding: 8px;
    img {
      height: 32px;
      width: 32px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__sitem-img {
      width: 30%;
  }

  &__item-lbl {
    width: 70%;
    padding-left: $space-m;
  }



  @include media-breakpoint-up('md') {
    &__item {

      flex-direction: column;
      aspect-ratio: 1 / 1;
      justify-content: center;
      align-items: center;

      max-width: 200px;

      border: 1px solid $color-border-light;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: $boxshadow-hover;
        border: 1px solid $color-border;
      }

    }

    .subcategories__item-img {
      width: auto;
    }
    .subcategories__item-img img {
      height: 80px;
      width: 80px;
    }

    .subcategories__item-lbl {
      font-weight: $fontweight-bold;
      font-size: $fontsize-m;
      text-align: center;
      line-height: $lineheight-l;
      width: 100%;
      padding-left: $space-m;
      padding-right: $space-m;
    }

    &__sitem {
      height: 100%;
      padding: $space-m;
      display: flex;
      flex-direction: column;
      border: 1px solid $color-border-light;
      transition: all 0.2s ease-in-out;
      justify-content: center;
      align-items: center;

      .subcategories__sitem-img {
        width: auto;
        margin-bottom: $space-m;

        img {
          height: auto;
          margin-left: auto;
          display: block;
          margin-right: auto;
        }
      }

      &:hover {
        box-shadow: $boxshadow-hover;
        border: 1px solid $color-border;
      }
    }
  }

}