iframe.youtube-iframe {
  max-width: 100%;
  aspect-ratio: 320 / 180;
  height: auto;
}
.youtube-box {

  .youtube-box__b {
    position: relative;
    cursor: pointer;

    img, picture {
      width: 100%;
      height: auto;
      display: block;
    }

    svg {
      transition: all .2s ease-in-out;
      position: absolute;
      width: 64px;
      height: 64px;
      top: calc(50% - 32px);
      left: calc(50% - 32px);
      opacity: .4;
    }

    &:hover {
      svg {
        opacity: .8;
      }
    }
  }
}