@use "sass:math";


@mixin col-mixin($i, $width, $suffix, $breakpoints: $media-breakpoints) {
  $min: map-get($breakpoints, $suffix);

  @if $min != 0 {
    .col-#{$suffix} {
      flex: 1 0 0%;
    }

    .col-#{$suffix}-nogrow {
      flex: 0 0 0%;
    }

    .col-#{$suffix}-#{$i} {
      width: $width;
    }

  } @else {
    .col {
      flex: 1 0 0%;
    }

    .col-nogrow {
      flex: 0 0 0%;
    }

    .col-#{$i} {
      width: $width;
    }
  }
}

.rows-list {
  .row + .row {
    margin-top: 0;
  }
}

.row {
  --y-spacing: #{$bootstrap--row-gap--space};

  &.no-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }

  &.gap-y-0 {
    --y-spacing: 0;
  }

  &.gap-y-s {
    --y-spacing: #{math.div($bootstrap--row-gap--space, 2)};
  }

  @each $suffix, $brpoint in $media-breakpoints {
    @include media-breakpoint-up($suffix) {

      &.gap-y-#{$suffix}-0 {
        --y-spacing: 0;
      }

      &.gap-y-#{$suffix}-s {
        --y-spacing: #{math.div($bootstrap--row-gap--space, 2)};
      }

      &.gap-y-#{$suffix}-base {
        --y-spacing: #{$bootstrap--row-gap--space};
      }

      &.no-wrap-#{$suffix} {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 0.5rem;
      }

      &.wrap-#{$suffix} {
        flex-wrap: wrap;
        padding-bottom: 0;
      }

    }
  }

  display: flex;
  flex-wrap: wrap;

  margin-top: calc(-1 * var(--y-spacing));
  margin-left: -0.5 * $bootstrap--row-gap--space;
  margin-right: -0.5 * $bootstrap--row-gap--space;

  & > * {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 0.5 * $bootstrap--row-gap--space;
    padding-left: 0.5 * $bootstrap--row-gap--space;
    margin-top: var(--y-spacing);
  }

  @each $suffix, $brpoint in $media-breakpoints {
    @include media-breakpoint-up($suffix){

      %colflex-#{$suffix} {
        flex: 0 0 auto;
      }

      @for $i from 1 through 12 {

        @if $brpoint > 0 {
          .col-#{$suffix}-#{$i} {
            @extend %colflex-#{$suffix};
          }
        } @else {
          .col-#{$i} {
            @extend %colflex-#{$suffix};
          }
        }

        $width: percentage(math.div(1, 12) * $i);
        @include col-mixin($i, $width, $suffix);
      }



      @if $brpoint > 0 {
        .col-#{$suffix}-20-proc {
          @extend %colflex-#{$suffix};
        }
      } @else {
        .col-20-proc {
          @extend %colflex-#{$suffix};
        }
      }

      @include col-mixin('20-proc', percentage(0.2), $suffix);

    }





  }
}

