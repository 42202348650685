$brbs--space: $space-xxs;
$brbs--sib-padding--space: $space-xxs;

@mixin brbs--item {
  font-size: $fontsize-xs;
}

@mixin brbs--sep {
  color: $color-border;
  //width: 0.5rem;
  //height: 0.5rem;
  //border-right: 1px solid $color-border-strong;
  //border-bottom: 1px solid $color-border-strong;
  //transform: rotate(-45deg);
  //position: relative;
}

@mixin brbs--item--hover {
  .brbs__item-link {
    color: $color-accent;
  }

}

@mixin brbs--sib-list {
  left: -1 * $brbs--sib-padding--space;
  min-width: calc(100% + 2 * $brbs--sib-padding--space);
  background-color: #fff;
  border: 1px solid $color-border;
}

@mixin brbs--sib-link {
  display: block;
  white-space: nowrap;
  color: $color-text-light;
  padding: $brbs--sib-padding--space;

}

@mixin brbs--sib-link--hover {
  background-color: $color-surface-highlight;
  color: $color-accent;
}



.brbs {

  margin-top: $space-s;

  &__list {
    display: flex;
    align-items: center;
    gap: $brbs--space;
    white-space: nowrap;
    overflow: auto hidden;
  }

  &__list-item {
    display: inline-flex;
    align-items: center;
    gap: calc($brbs--space + 3px);

    &.brbs__list-item--with-sib {

      .brbs__item-text {
        color: $color-text-light;
        cursor: default;
      }

    }
  }

  &__item {
    display: inline-block;
    align-items: center;
    position: relative;
    z-index: 100;

    @include brbs--item();

    &:hover {
      @include brbs--item--hover();

      .brbs__sib-list {
        display: block;
      }
    }
  }

  &__sep {
    @include brbs--sep();
  }

  &__item-link {
    display: inline-block;
    padding: $space-xs 0;
  }

  &__item-text {
    display: inline-block;
    padding: $space-xs 0;
    color: $color-text-extralight;
  }

  &__sib-list {
    display: none;
    position: absolute;
    @include brbs--sib-list();
  }

  &__sib-item {

  }

  &__sib-link {
    @include brbs--sib-link();
    &:hover {
      @include brbs--sib-link--hover();
    }
  }

}

@include media-breakpoint-up('md'){
  .brbs {
    &__list {
      overflow: visible;
    }
  }
}