
.c-quantity {
  display: flex;
  border: 1px solid $color-border;

  &.is-inline {
    display: inline-flex;

  }

  &.is-small {
    input {
      font-size: 14px;
      padding: 6px 3px;
      width: 40px;
    }
    .c-quantity_inc, .c-quantity_dec {
      font-size: 22px;
      padding: 0px 6px;
    }

  }


  input {
    border: 0px;
    outline: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 10px 5px;
    background-color: #fff;
    border-left: 1px solid $color-border;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }

  .c-quantity_unit {
    padding: 15px 5px 10px 5px;
    background-color: #fff;
    border-right: 1px solid $color-border;
    font-size: 13px;

  }

  .c-quantity_inc, .c-quantity_dec {
    width:36px;
    text-align: center;
    flex-shrink: 0;
    font-size: 28px;
    line-height: 36px;
    padding: 0px 10px;
    cursor: pointer;
    background-color: #fafafa;
    color: #666;
    user-select: none;
    &:hover{
      color: #000;
      background-color: #f0f0f0;
    }
  }


  &.small {
    max-width: 180px;
    height: 30px;

    input {
      font-size: 14px;
      padding: 4px 0 0 0;
    }

    .c-quantity_unit {
      padding: 10px 4px;
    }

    .c-quantity_inc, .c-quantity_dec {
      width: 26px;
      font-size: 16px;
      line-height: 28px;
    }
  }

}