@use "sass:math";

$search--height: 2.6rem;
$search--fontsize: $fontsize-s;
$search--icon-height: $space-l;

@mixin search--form {
  border: 1px solid $form--color-input-border;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 0 0 transparent;
  transition: outline 0.2s ease-in-out;
}

@mixin search--form-icon {
  height: 100%;
  flex-grow: 0;
  display: inline-flex;
  align-items: center;
  color: $form--color-input-icon;
  padding-left: math.div($search--height - $search--icon-height, 2);
  padding-right: math.div($search--height - $search--icon-height, 2);

  svg {
    height: $search--icon-height;
  }
}

@mixin search--form-input {
  font-size: $search--fontsize;
  border-radius: 12px;
  padding-left: 12px;
  flex-grow: 1;

}

@mixin search--form-select-wrap {
  font-size: $search--fontsize;
  line-height: $search--height;
  padding-left: $space-xs;
  flex-grow: 0;
  flex-shrink: 0;
  //max-width: 240px;
}

@mixin search--from-select {
  line-height: $search--height;
  padding: 0 2.4rem 0 $space-m;
  white-space: nowrap;
}

@mixin search--form-select-left-divider {
  height: $search--height - 1rem;
  margin-top: 0.5rem;
  //border-left: 1px solid $colo;
}

@mixin search--form-select-right-divider {
  //height: $search--height - 1rem;
  //margin-top: 0.5rem;
  //border-left: 1px solid $form--color-input-border;
}

@mixin search--form-submit {
  font-size: $search--fontsize;
  background-color: $color-surface;
  color: $color-surface-dark;
  padding-left: $space-xs;
  padding-right: $space-xs;
  margin: 0 2px 0 0;
  line-height: $search--height - ($space-xxs *2);
  height: calc(100% - 4px);
  cursor: pointer;
  border-radius: 0 2px 2px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .search__form-submit-label {
    display: none;
  }

  .search__submit-icon {
    width: 24px;
    height: 24px;
  }
  .search--form-submit-after-icon {
    width:24px;
    height: 24px;
  }
}

@mixin search--form-submit-hover {
  background-color: $color-surface-highlight;
}

$search--box-shadow-focus: 0 0 0 1px $color-accent;

.search {
  position: relative;
  width: 100%;

  &__form {
    width: 100%;
    display: flex;
    align-items: center;
    height: $search--height;
    @include search--form();
  }

  &--focused {
    .search__form {
      box-shadow: $search--box-shadow-focus;
    }

  }

  &--autocomplete-visible {
    .search__form {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom:0px;
      left:1px;
      right: 1px;
      height: 2px;
      background-color: #ffffff;
      z-index:20001;
    }

  }


  &__form-input {
    height: 100%;
    @include search--form-input();
  }

  &__form-select-wrap {
    display: flex;
    height: 100%;

    @include search--form-select-wrap();

    &:before {
      content: "";
      @include search--form-select-left-divider();
    }

    &:after {
      content: "";
      @include search--form-select-right-divider();
    }
  }

  &__form-select {
    height: 100%;
    width: 100%;
    @include search--from-select();
  }

  &__form-submit {
    @include search--form-submit();

    &:hover {
      @include search--form-submit-hover();
    }

    svg.ionicon {
      width: 1.3rem;
      height: 1.3rem;
      vertical-align: -7px;
    }
  }


  &__form-icon {
    @include search--form-icon();

    svg {
      width: auto;
      display: block;
    }
  }

  .search__autocomplete {
    display: none;
    position: absolute;
    width: 100%;
    background-color: $color-surface;
    //
    border-left: 1px solid $color-border;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    box-shadow: $search--box-shadow-focus;

    border-radius: 0 0 2px 2px;

    z-index: 2000;
    max-height: 50vh;
    overflow: auto;

    a {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      font-size: 14px;
      line-height: 14px;

      .search__autocomplete-icon {
        width: 20px;
        height: 20px;
        color: #bebebe;
        margin-right: 0.75rem;
        line-height: 14px;
      }

      &:hover, &.selected {
        background-color: $color-surface-highlight;
        color: $color-text-on-surface-highlight;

        .search__autocomplete-icon {
          color: $color-text-on-surface-highlight;
        }
      }
    }
  }

  .search__autocomplete-loader {
    display: none;
  }


  &.is-opened {
    .search__autocomplete {
      display: block;
    }
  }


  &--autocomplete-loading {

    .search__autocomplete {
      display: block;
      min-height: 70px;
    }

    .search__autocomplete-loader {
      display: block;
      z-index: 2;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 30px 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      svg {
        width: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

  }
}


.menu-search {
  opacity: 0;
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 550;
  transition: all 0.2s ease-in-out;
}

.c-menu-opened.menu-search {
  display: block !important;
    opacity: 1;
    transform: none;
    background-color: #fff;
}

.comp-search-opened {

  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 500;
  }

  .c-menu__pre_mobile {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 600;
    .svgbutton {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .search {
    padding: 48px 10px 10px 10px;
    width: 100%;
    background-color: #fff;
    display: flex !important;
    align-items: center;

    flex-wrap: wrap;

    .search--form-submit-after-icon {
      font-size: 1.5em;
      vertical-align: -0.3em;
    }

    .search__autocomplete {
      position: static;
      width: 100%;
      max-width: none;
      margin-top: 20px;
      max-height: calc(100vh - 140px);
      overflow: auto;
    }

    .comp-header-search__form {
      flex-grow: 1;
      margin-right: 20px;

      input.c-search__input {
        width: 100%;
      }
    }
  }





}

@include media-breakpoint-up('lg'){
  .menu-search {
    display: block;
    position: static;
    transform: none;
    opacity: 1;
  }
}