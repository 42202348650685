.link {
  //font-family: $fontfamily-condensed;
  font-size: #{$fontsize-m};
  height: #{$fontsize-m + $space-l};
  display: inline-flex;
  align-items: center;
  outline: 0;
  position: relative;

  &--small {
    font-size: #{$fontsize-s};
    height: #{$fontsize-s +  $space-s};
  }

  &::after {
    position: absolute;
    content: '';
    top:0;
    left:0;
    width: 20px;
    border-top: 1px solid $color-accent;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

}

.file-link {
  display: inline-flex;
  align-items: center;
  gap: $space-s;
  font: $font-base;

  span {
    border-bottom: 1px solid $color-border;
  }

  img {
    height: 28px;
    width: 28px;
  }

  &:hover {
    span {
      border-bottom-color: $color-accent-hover;
    }
  }

}