

.product-box-list.product-box-list--grid {
  @include media-breakpoint-up('md') {
    --columns: 3;
  }

  @include media-breakpoint-down('md') {
    overflow-x: auto;
    grid-template-columns: repeat(2, 66.6666666667%);
    grid-auto-columns: 66.6666666667%;
    grid-auto-flow: column;
    padding-bottom: .5rem;
  }

  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));

  display: grid;
  width: 100%;

  gap: $space-row-gap;
  row-gap: $space-row-gap;
}


.product-box-list.product-box-list--list {
  display: flex;
  width: 100%;
  row-gap: $space-row-gap;
  flex-direction: column;
}

@import "grid-product-box";
@import "list-product-box";