
@mixin button-size-s {
  --btn-font-size: #{$fontsize-s};
  --btn-padd-x: #{$space-xl};
  --btn-height: #{$fontsize-s + 2 * $space-xs};

  &.button--icon {
    --btn-padd-x: #{$space-xs};
  }
}

@mixin button-size-base {
  --btn-font-size: #{$fontsize-s};
  --btn-padd-x: #{$space-s};
  --btn-height: #{$fontsize-m + $space-l};
}

@mixin button-size-l {
  --btn-font-size: #{$fontsize-m};
  --btn-padd-x: #{$space-m};
  --btn-height: #{$fontsize-l + $space-l};
}

@mixin button-style-base {
  --btn-accent-color: #{$color-surface-highlight};
  --btn-text-color: #{$color-text-light};

  --btn-hover-accent-color: #{$color-surface-highlight-hover};
  --btn-hover-text-color: #{$color-text-on-surface-highlight-hover};

  --btn-outline-bg-color: transparent;
  --btn-hover-outline-bg-color: #{rgba($color-surface-highlight-hover, .25)};

  --btn-outline-text-color: #fff;
  --btn-hover-outline-text-color: #fff;
}

@mixin button-style-primary {
  --btn-accent-color: #{$color-accent};
  --btn-text-color: #{$color-text-on-accent};

  --btn-hover-accent-color: #{$color-accent-hover};
  --btn-hover-text-color: #{$color-text-on-accent};

  --btn-outline-bg-color: transparent;

  --btn-hover-outline-bg-color: #{rgba($color-accent-hover, .09)};

  --btn-outline-text-color: #{$color-accent};
  --btn-hover-outline-text-color: #{$color-accent-hover};
}


@mixin button-style-secondary {
  --btn-accent-color: #{$color-accent-grayed};
  --btn-text-color: #{$color-text-on-accent-grayed};

  --btn-hover-accent-color: #{$color-accent-grayed-hover};
  --btn-hover-text-color: #{$color-text-on-accent-grayed};

  --btn-outline-bg-color: transparent;

  --btn-hover-outline-bg-color: #{rgba($color-accent-grayed-hover, .09)};

  --btn-outline-text-color: #{$color-accent-grayed};
  --btn-hover-outline-text-color: #{$color-accent-grayed-hover};
}


@mixin button-style-black {
  --btn-accent-color: #{$color-text-light};
  --btn-text-color: #{$color-surface};

  --btn-hover-accent-color: #{$color-text};
  --btn-hover-text-color: #{$color-text-on-accent};

  --btn-outline-bg-color: transparent;

  --btn-hover-outline-bg-color: #{rgba($color-text-light, .09)};

  --btn-outline-text-color: #{$color-text-light};
  --btn-hover-outline-text-color: #{$color-text};
}


@mixin button-base($style: 'base', $size: 'base'){

  @if $style == 'base' {
    @include button-style-base();
  }

  @if $style == 'primary' {
    @include button-style-primary();
  }

  @if $style == 'secondary' {
    @include button-style-secondary();
  }

  @if $size == 'base' {
    @include button-size-base();
  }

  @if $size == 's' {
    @include button-size-s();
  }

  @if $style == 'black' {
    @include button-style-black();
  }

  display: inline-flex;
  align-items: center;
  outline: 0;
  white-space: nowrap;

  padding-left: var(--btn-padd-x);
  padding-right: var(--btn-padd-x);
  gap: calc(var(--btn-padd-x) / 2);
  height: var(--btn-height);
  transition: all 0.2s ease-in-out;

  color: var(--btn-text-color);
  font-size: var(--btn-font-size);
  background-color: var(--btn-accent-color);

  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-accent-color);
    color: var(--btn-hover-text-color);
    > svg {
      color: var(--btn-accent-text-color);
    }
  }

  & > svg {
    //margin-top: calc(var(--btn-font-size) * -0.17); //korekta wyrównania z ikonką

    margin-left: calc(var(--btn-padd-x) * -0.25);
    color: var(--btn-text-color);
    display: block;
    height: calc(var(--btn-height) * .5);
    width: auto;

    &:only-child {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &:last-child {
      margin-left: 0;
      margin-right: calc(var(--btn-padd-x) * -0.25);
    }
  }

  &[disabled], &--disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  &--outline {
    border: 1px solid var(--btn-accent-color);
    background-color: var(--btn-outline-bg-color);
    color: var(--btn-outline-text-color);

    & > svg {
      color: var(--btn-outline-text-color);
    }

    &:hover {
      background-color: var(--btn-hover-outline-bg-color);
      color: var(--btn-hover-outline-text-color);
      & > svg, & > use svg {
        color: var(--btn-hover-outline-text-color);
      }
    }
  }

}

.button {

  @include button-base();

  &--primary {
    @include button-style-primary();
  }

  &--secondary {
    @include button-style-secondary();
  }

  &--black {
    @include button-style-black();
  }

  &--s {
    @include button-size-s();
  }

  &--l {
    @include button-size-l();
  }

  &--block {
    display: flex;
    width: 100%;
    justify-content: center;

  }

}