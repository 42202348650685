.text-page {
  margin-bottom: $space-m;

  .text-page__content {
    --text-p-spacing: 1rem;
    line-height: 1.65em;
  }

}

.text-page__content, .text-block {
  font-size: $fontsize-m;
  font-weight: 400;

  i, em {
    font-style: italic;
  }

  b, strong {
    font-weight: 500;
  }

  * + p, * + ul, * + ol, * + h2, * + h3, * + h4, * + h5, * + h6, * + figure {
    margin-top: var(--text-p-spacing, 1em);
  }

  li p {
    display: inline-block;
  }

  ul {
    list-style: none;
    list-style-position: outside;
    margin-left:1.5em;

    li:before {
      content: "\25aa";
      color: $color-accent-hover;
      display: inline-block;
      margin-right: 0.5em;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left:2em;
  }

  h1 {
    font-size: 2.2em;
    font-weight: 700;
    line-height: 1.2em;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2em;
  }


  h3 {
    font-size: 1.5em;
    font-weight: $fontweight-bold;
    line-height: 1.2em;
  }

  h4 {
    font-size: 1.1em;
    font-weight: $fontweight-bold;
    line-height: 1.2em;
  }

  a {
    color: $color-accent;
    text-decoration: underline;
    text-decoration-color: $color-border-light;

    &:hover {
      color: $color-accent-hover;
      text-decoration-color: $color-accent-hover;
    }
  }



  blockquote {
    background-color: $color-border-light;
    position: relative;
    padding: 1.5em 1.5em 1.5em 90px;
    font-style: italic;
    margin-top: 2em;

    &:before{
      content: "\201E";
      position: absolute;
      top: -66px;
      left: 30px;
      font-size: 180px;
      color: $color-accent;
      opacity: 0.3;
    }

    &:after{
      content: "\201D";
      position: absolute;
      bottom: -34px;
      right: 20px;
      font-size: 90px;
      color: $color-accent;
      opacity: 0.3;
    }
  }


  img {
    max-width: 100%;
    height: auto;
    display: block;

    &.aligncenter {
      margin-left: auto;
      margin-right: auto;
    }

    &.alignright {
      float: right;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }

    &.alignleft {
      float: left;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
}