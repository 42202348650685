
.header__actions {

  &-links {
    display: flex;
    flex-wrap: nowrap;
    gap: $space-l;
  }

  a, span {
    display: block;
    position: relative;

    .badge {
      display: block;
      position: absolute;
      top:-10px;
      right: 0;
      color: #fff;
      background-color: $color-accent-hover;
      border-radius: 4px;
      padding: 4px;
    }

    &.header__actions-link-like {
      cursor: pointer;
    }

    img {
      height: 20px;
      width: 20px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4px;
      opacity: 0.9;
    }

    span {
      color: #666;
      font-size: 11px;
      display: none;
    }

    &:hover {
      img {
        opacity: 1;
      }
      .badge {
        color: #fff !important;
      }
      span {
        color: #000;

      }
    }
  }
}

@include media-breakpoint-up('md'){
  .header__actions {
    &-links {
      a, span {
        span {
          display: block;
        }
      }
    }
  }
}
