@mixin grid--product-box{
  border: 1px solid #eee;
  gap: $space-xxs;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

@mixin grid--product-box-hover{
  border: 1px solid $color-border;
  box-shadow: $boxshadow-hover;
  position: relative;
  transition: all 0.2s ease-in-out;
  //height: calc(100% + 80px);
  z-index: 100;

}

@mixin grid--product-box-img {
  //height: 200px;
}

@mixin grid--product-box-label{
  @include media-breakpoint-up('md') {
    padding-left: $space-m;
    padding-right: $space-m;
    font-size: $fontsize-m;
  }

  padding-right: $space-s;
  padding-left: $space-s;
  font-size: $fontsize-s;

  font-weight: $fontweight-bold;
  line-height: $lineheight-l;
}


@mixin grid--product-box-params-list{
  font-size: $fontsize-xs;
  padding-left: $space-m;
  padding-right: $space-m;
  flex-grow: 1;
}

@mixin grid--product-box-params-list-item{

}

@mixin grid--product-box-params-link{
  padding-top: $space-xxs;
  padding-bottom: $space-xxs;
  display: block;
  line-height: $space-l;
}

@mixin grid--product-box-params-label{

}

@mixin grid--product-box-params-value{
  font-weight: $fontweight-base;
  font-size: $fontsize-s;
}

@mixin grid--product-box-footer {
  padding-top: 0;
  transition: 0.2s ease-in-out all;
  position: relative;
  margin-top: $space-s;
  flex-grow: 0;
}

@mixin grid--product-box-footer-btn {
  display: block;
  background-color: $color-accent;
  color: $color-text-on-accent;
  text-align: center;
  font-size: $fontsize-s;
  font-weight: $fontweight-bold;
  padding: $space-s $space-m;
  white-space: nowrap;

  &:hover {
    background-color: $color-accent-hover;
  }
}

.product-box-list.product-box-list--grid {

  .product-box {
    display: flex;
    flex-direction: column;

    @include grid--product-box();

    &:hover {
      @include grid--product-box-hover();
    }

    &__img {
      @include grid--product-box-img();

      display: flex;
      width: auto;
      flex-direction: column;
      align-items: center;

      img {
        transition: all 0.2s ease-in-out;
        max-height: 100%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__label {
      display: block;
      @include grid--product-box-label();
    }

    &__label-brand {
      font-size: $fontsize-xs;
      font-weight: 500;
      margin-top: 0.5rem;
    }

    &__p-list {
      @include grid--product-box-params-list();
    }

    &__p-item {
      @include grid--product-box-params-list-item();
    }

    &__p-link {
      @include grid--product-box-params-link();
    }

    &__p-lbl {
      @include grid--product-box-params-label();
    }

    &__p-val {
      @include grid--product-box-params-value();
    }

    &__p-sku {
      font-size: $fontsize-xxs;
      color: $color-text-lighter;
    }

    &__footer {
      font-size: $fontsize-xs;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: stretch;
      @include grid--product-box-footer();
    }

    &__footer-btn {
      @include grid--product-box-footer-btn();
    }

    &__footer-avl {
      background-color: $color-surface-light;
      color: $color-text-lighter;
      padding: $space-s $space-m;
      font-size: $fontsize-xs;
      font-weight: $fontweight-bold;

    }

  }

}