.cat-roots {

  &__list {
    padding-bottom: $space-m;
    gap: $space-m;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;

  }

  &__item {
    display: flex;
    align-items: stretch;
    flex-grow: 0;
    flex-shrink: 0;
    width: 140px;
  }

  &__link {
    display: flex;
    padding: $space-m;
    align-items: center;
    background: #fff;
    flex-direction: column;
    width: 100%;
    &:hover {
      color: $color-accent-hover;
    }

    &-icon {
      height: 64px;
      width: auto;
      max-width: 64px;
      margin-bottom: $space-s;
    }

    & > span {
      display: block;
      text-align: center;
    }
  }

  &__all-link {
    flex-direction: row;
    .cat-roots__link-icon {
      height: 24px;
      width: auto;
      max-width: 24px;
      margin-bottom: 0;
      margin-right: $space-m;
    }
  }


}

@include media-breakpoint-up('md') {

  .cat-roots {
    background-color: $color-surface;
    min-height: 100%;
    padding: $space-xs 0;

    &__list {
      border-bottom: 1px solid $color-border-light;
      margin: 0 $space-xl 0 $space-xl;
      padding-bottom: $space-m;
      flex-direction: column;
      gap: 0;
    }

    &__item {
      display: block;
      width: 100%;
    }

    &__link {
      display: flex;
      padding: $space-xs 0;
      align-items: center;
      flex-direction: row;
      width: auto;

      &:hover {
        color: $color-accent-hover;
      }
    }

    &__all-link {
      margin: $space-s $space-xl 0 $space-xl;
      width: auto;
    }

    &__link-icon {
      height: 24px;
      width: auto;
      max-width: 24px;
      margin-right: $space-s;
      margin-bottom: 0;
    }

  }
}