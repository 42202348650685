@use "sass:math";

.blog-article-list {
  margin-top: $space-xl;
}

.blog-art-box {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-border-light;
  padding-bottom: $space-m;
  height: 100%;
  transition: all 0.2s ease-in-out;
  font-family: $fontfamily-condensed;
  background-color: $color-surface;

  .blog-art-box__img {
    overflow: hidden;
    display: block;
    aspect-ratio: var(--img-aspect-ratio, 300/170);

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .blog-art-box__b {
    flex-grow: 1;
  }

  .blog-art-box__title {
    display: block;
    font-size: $fontsize-l;
    font-weight: 500;
    line-height: $lineheight-l;
    padding: $space-m $space-m $space-xxs $space-m;
  }

  .blog-art-box__date {
    font-size: $fontsize-xs;
    color: $color-text;
    padding: 0 $space-m;
    display: none;
  }

  .blog-art-box__lead {
    font-size: $fontsize-s;
    line-height: $lineheight-l;
    padding: 0 $space-m 0 $space-m;
    flex-grow: 1;
  }

  &:hover {
    border: 1px solid $color-border;
    box-shadow: $boxshadow-hover;
  }

  &--small {
    .blog-art-box__title {
      font-size: $fontsize-s;
      font-weight: $fontweight-bold;
      padding: $space-s;
    }

    .blog-art-box__date {
      font-size: $fontsize-xxs;
      color: $color-text;
      padding: 0 $space-s;
    }
  }

  .blog-art-box__btn {
    display: none;
    padding-left: $space-m;
    margin-right: $space-m;
    padding-top: $space-m;

  }
}

.blog-article-list--hor .blog-art-box {
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: auto;

  + .blog-art-box--box {
    margin-top: $space-row-gap;
  }

  .blog-art-box__title {
    display: block;
    font-size: $fontsize-l;
    line-height: $lineheight-l;
  }

  .blog-art-box__img > div {
    width: 100%;
    height: 100%;
  }

  .blog-art-box__b {
    margin-bottom: $space-m;
  }

  .blog-art-box__img {
    width: auto;
    aspect-ratio: unset;
    background-color: #942343;
    grid-area: 1/1/ 4 / auto;
    margin-right:  0;
  }

  .blog-art-box__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }


  .blog-art-box__btn {
    padding-bottom: $space-m;
  }

}

@include media-breakpoint-up('md'){

  .blog-art-box {
    .blog-art-box__btn {
      display: flex;
      align-items: flex-end;
    }
  }

  .blog-article-list--hor .blog-art-box {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;

    .blog-art-box__b {
      margin-bottom: 0;
    }

    .blog-art-box__img {
      margin-right:  #{math.div($space-row-gap, 2)};
    }

    .blog-art-box__img {
      width: auto;
      aspect-ratio: var(--img-aspect-ratio, 300/170);
    }
  }

}