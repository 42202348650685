$header-nav--fontsize: $fontsize-s;

@mixin header-nav {
  font-size: $header-nav--fontsize;
  font-weight: 500;
  background-color: $color-surface;
  border-top: 1px solid $color-border-extra-light;
  border-bottom: 1px solid $color-border-extra-light;
}

@mixin header-nav--nav {
  font-size: $header-nav--fontsize;
}

@mixin header-nav--link-hover {
  background-color: $color-surface;
  &:after {
    background-color: $color-accent;
  }
}

@mixin header-nav--link-or-text {
  padding:  $space-l $space-l $space-l $space-l;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: $fontsize-m;

  .icon {
    width: 16px;
    height: 16px;
    margin-left: $space-xs;
    color: $color-border;
    transition: all 0.2s ease-in-out;
  }

}

@mixin header-nav--drop() {

  .header-nav__link {

  }

  &:hover {

    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 1px;
      right: 1px;
      border-bottom: 1px solid $color-surface;
      height: 1px;
      z-index: 600;
    }

    .header-nav__link--drop {
      color: $color-text;
      background-color: $color-surface;

      .icon {
        color: $color-accent;
        transform: rotate(180deg);
      }
    }

  }
}


//import overwrites
@import "../../../style_mixins";

.header-nav {
  display: none;
}

@include media-breakpoint-up(lg) {

  .header-nav {

    display: block;

    @include header-nav();

    &__nav {
      display: flex;
      @include header-nav--nav();

      & > * {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &__link, .header-nav &__text {
      @include header-nav--link-or-text();
    }

    //
    //&__link--drop {
    //  @include header-nav--link-drop();
    //}

    &__category-drop, &__tax-drop {
      @include header-nav--drop();
    }

     &__link {
      &:hover {
        @include header-nav--link-hover();
      }
    }
  }

  @import "categories-menu";
}


//@import "categories-columns";
