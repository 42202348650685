
.p-meta {
  display: flex;
  align-items: stretch;
  padding-top: $space-xl;
  padding-bottom: $space-xs;
  margin-bottom: $space-xs;
  flex-grow: 1;

  .p-meta__img-w {
    border: 1px solid $color-border;
    padding: $space-m;
    height: 2.75rem;
    margin-right: $space-xl;
  }

  .p-meta__img {
    display: block;
    height: 100%;
  }

  .p-meta__d {
    display: flex;
    flex-direction: column;
  }

  .p-meta__di {

    font-size: $fontsize-s;

    .p-meta__di-l {
      color: $color-text-light;
      margin-right: $space-m;
    }

    .p-meta__di-v {
      color: $color-text-light;
    }
  }

}