footer {
  padding-top: $space-xxl;
  padding-bottom: $space-xxl;
  background-color: #333;

  .footer--logo {
    height: 40px;
    width: auto;
  }

  .footer--copy {
    margin-top: $space-m;
    color: #fff;
    font-size: $fontsize-xs;
    font-weight: 300;
  }

  .widget {
    margin-top: $space-m;
    color: #fff;
    font-size: $fontsize-s;
    line-height: $lineheight-xl;
    font-weight: 400;
  }

  .widget_nav_menu {
    .name {
      font-weight: 600;
      margin-bottom: $space-xxs;
      padding-bottom: $space-xxs;
    }

    .menu {
      .menu-item {
        a {
          display: inline-block;
          &:hover {
            text-decoration: underline;
            text-decoration-color: rgba(255,255,255,.8);
          }
        }
      }
    }
  }


  .wp-block-social-links {
    display: flex;
    margin-left: calc(-1 * $space-m);
    .wp-block-social-link-anchor {

      margin-left: $space-m;

      svg {
        path {
          fill: #fff;
        }
      }

      .wp-block-social-link-label {
        display: none;
      }
    }
  }

}