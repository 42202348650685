.gray-box {
  background-color: $color-surface-light;
  padding: $space-xxl 40px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, .1);

  &:hover {
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, .3);
  }

  &__img {
    width: 50%;
    order: 2;

    &-w {
      width: 60%;
      display: block;
      margin-left: auto;
      background-color: $color-surface;
      padding: 2rem;
      border-radius: 16px;
      text-align: center;
      aspect-ratio: 1/1;
    }

    img {
      max-width: 92px;
    }
  }

  &__b {
    width: 50%;
  }

  &__t {
    font-size: $fontsize-xl;
    font-weight: $fontweight-bold;
  }

  &__desc {
    margin-top: $space-xl;
    font-size: $fontsize-m;
    line-height: $lineheight-l;
  }

}