select {
  --b-color: #{$color-border-strong};

  background-image: linear-gradient(45deg, transparent 50%, var(--b-color) 50%), linear-gradient(135deg, var(--b-color) 50%, transparent 50%);

  background-position: calc(100% - 24px) calc(1em + 2px), calc(100% - 16px) calc(1em + 2px);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;

  transition: all 0.2s ease-in-out;

  &:hover {
    --b-color: #{$color-border-extrastrong};
  }
}

