/*! StackPeak - Catalog v1.0  */
@use 'sass:math';
@import "reset";
@import '../node_modules/normalize.css/normalize';

$variable-prefix:             sp- !default;

@import 'tokens';
@import "style_mixins";
@import 'bootstrap/bootstrap';

@import "website/commons/tax-drop/tax-drop";
@import "website/commons/nav-drop/nav-drop-full";
@import "website/commons/content-header/content-header-breadcrumbs";
@import "website/commons/content-header/content-header";

@import 'website/commons/search/search';
@import 'website/commons/button/button';
@import 'website/commons/button/link';
@import "website/commons/section";

@import "website/commons/pagination/paginators";

@import 'website/layout/header';
@import 'website/layout/footer';
@import 'website/subcategories';

@import "website/blocks/card";
@import "website/blocks/cat-roots";
@import "website/blocks/pictograms";
@import "website/blocks/buttons";
@import "website/blocks/title";
@import "website/commons/text-block";
@import "website/blocks/youtube";
@import "website/blocks/image-gallery";
@import "website/blocks/google-maps-embed";
@import "c-quantity";

$body--color-text: $color-text;
$body--font: $font-base;


body {
  color: $body--color-text;
  font: $body--font;
}

.categories.section {
  margin-top: $space-xxxl;
}

@import 'utils/icon';
@import 'utils/select';
@import 'utils/ratio';

@import "utils/modal";
@import "utils/app-form";

@import "website/catalog/cat-tree";
@import "website/catalog/product-box-list";
@import "website/catalog/browse-products-list";
@import "website/commons/text-page";
@import "website/blog/blog-article-box";
@import "website/blog/sidebar";
@import "website/product/product-section";

@import "website/fields-index/fields-index";
@import "website/footer";
@import "website/blocks/banner";

@import "website/box";
@import "website/gray-box";

@import "vendor/splidejs/template/default";

@import "loader";


.popular-products {
  margin-top: $space-xl;
  margin-bottom: $space-xxxl;
}

.banner.full {
  margin-top: $space-xxxl;
  margin-bottom: $space-xxxl;
}

//
//
//svg.icon {
//  width: 100px;
//  height: 100px;
//  color: #019640;
//}
//
//svg > use > svg {
//  height: 100px;
//  width: 100px;
//}
//
//body {
//  stroke: #333;
//}
//
//svg > use > svg, svg > use > svg path {
//  fill: currentColor;
//  stroke: currentColor;
//  color: inherit;
//}
//
//svg {
//  color: inherit;
//}

//
//svg.icon {
//  height: 1em;
//  width: auto;
//}
//

//
//@import "section/header/header_style_one";
//@import "section/section";
//@import "sections/main-page";
//@import "section/product-section";
//
//@import "text/buttons";
//@import "text/btn-drop";
//
//@import "components/catagories-picker";
//@import "components/search";
//@import "components/browse-category-tree";
//@import "components/product-box";
//@import "components/browse-header";
//@import "components/breadcrumbs";
//@import "components/style-switcher";
//
//@import "components/paginators";
//
//@import "components/main-menu";
//
//@import "components/bpl";
//@import "components/page-size-switcher";
//
//@import "components/modal";
//
//@import "flex/ratio";
//@import "flex/content_img_block";
//
//@import "sections/footer/footer";
//@import "components/blog/blog-art-box";
//@import "components/text-page";
//@import "components/blog-article-list";
//@import "components/field-index";
//@import "components/mobile-menu";
.contact {
  h3 {
    font-size: $fontsize-m;
    font-weight: 500;
  }

  p {
    font-size: $fontsize-s;
    margin-top: 1em;
    line-height: $lineheight-l;
  }
}


.p-contact-ico {
  position: relative;

  .p-contact__ico {
    position: absolute;
    transform: rotate(11deg);
    bottom:-12px;
    right:-12px;
    svg {
      width: 128px;
      height: 128px;
      color: $color-accent;
    }
  }
}

//button svg {
//  height: 1em;
//}
//
