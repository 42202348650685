$content-header--hor-space: $space-xl;

@mixin content-header{
  margin-top: $content-header--hor-space;
  margin-bottom: $space-xxxl;
}

@mixin content-header--title{
  padding-bottom: $space-s;
}

@mixin content-header--h {
  @include media-breakpoint-up('md') {
    margin-top: $space-l;
    font-size: $fontsize-xl;
  }

  position: relative;
  margin-top: $space-xs;
  font-size: $fontsize-l;
  font-weight: $fontweight-strong;

  margin-bottom: 0;

  @include title-accent();
}

.content-header {
  @include content-header();

  &.content-header--no-title {
      margin-bottom: $space-m;
  }

  &__title {
    @include content-header--title();

    &--banner {
      aspect-ratio: 300/70;
      background-size: cover;
      padding: $space-l;
      margin-top: $space-l;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: $space-l;
      background-position: center;

      border: 1px solid $color-border-light;

      & > * {
        position: relative;
        z-index: 2;
      }

      .content-header__h {
        font-size: $fontsize-xxxl;
        line-height: $lineheight-l;
        width: 66.6666666667%;

      }

      &::before {
        content: '';
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        background: linear-gradient(70deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.1) 100%);
        z-index: 1;
        backdrop-filter: blur(10px);
      }
    }

  }

  &__meta {
    margin-top: $space-l;
    font-size: $fontsize-s;

  }

  &__h {
    @include content-header--h();
  }

}

