.pictograms {
  background-color: $color-surface;

  &__list {
    display: flex;
  }

  &__icon {
    height: 32px;

  }

  &__spacer {
    display: none;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: $space-xs;
    flex-direction: column;
    font-size: $fontsize-s;
    gap: $space-s;
    & > span {
      text-align: center;
    }
  }

  &__item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
  }
}

@include media-breakpoint-up('md'){
  .pictograms {
    &__list {
      justify-content: space-evenly;
    }

    &__item {
      flex-grow: unset;
      flex-shrink: unset;
      flex-basis: unset;
    }

    &__link {
      padding: $space-l 0;
      flex-direction: row;
      font-size: $fontsize-m;
      gap: unset;
    }

    &__icon {
      height: 64px;
      margin-right: $space-s;

    }

    &__spacer {
      display: block;
      border-left: 1px solid $color-border-light;
      height: 50px;
      transform: translateY(50%);
    }
  }
}