body.modal-opened {
  overflow: hidden;
}

.modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);

  overflow: auto;

  visibility: hidden;
  opacity: 0;

  transition: visibility 0.2s, opacity 0.2s ease-in-out;

  &.opened {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.2s, opacity 0.2s ease-in-out;

    .modal-dialog {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.full-height {
    .modal-dialog {
      max-height: calc(100vh - 80px);
      overflow: auto;
    }
  }

  &.wide {
    .modal-dialog {
      width: 100%;
      max-width: 1140px;
    }
  }

  .modal-abs-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $color-text;
    z-index: 2;

    .close {
      cursor: pointer;
    }
  }

  .modal-body .message {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 30px;
    font-size: 14px;
    font-weight: 500;
    color: #2b2b2b;
    line-height: 24px;
  }

  .modal-dialog {
    max-width: 600px;
    max-height: calc(100vh - 80px);

    margin: 40px auto;
    background-color: #fff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);

    transform: translateY(-30%);
    opacity: 0;

    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    .modal-header {
      padding: 22px 30px;
      display: flex;
      justify-content: space-between;
      vertical-align: middle;

      .modal-title {
        color: #2b2b2b;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
      }

      .close {
        color: $color-text;
        outline: 0;

        svg {
          height: 28px;
          width: 28px;
          pointer-events: none;
        }
      }
    }
  }


  .modal-content {
    padding: 64px 22px 52px 22px;

    .name {
      font-size: $fontsize-l;
      color: #2b2b2b;
      line-height: 30px;
      margin-bottom: 20px;
      text-align: center;
      font-weight: 500;
    }

    .content {
      font-size: $fontsize-m;
      color: #2b2b2b;
      line-height: 20px;
      text-align: center;
    }
  }
}


@media screen and (max-width: 576.02px) {
  .modal .modal-dialog {
    max-height: 100vh;

    margin: 0px auto;
  }
}

