.image-gallery {

  display: grid;
  gap: $space-row-gap;

  @each $suffix, $brpoint in $media-breakpoints {
    @include media-breakpoint-up($suffix) {
      @for $i from 1 through 12 {
        @if $brpoint > 0 {
          &.image_gallery--#{$suffix}-i-#{$i} {
            grid-template-columns: repeat($i, 1fr);
          }
        } @else {
          &.image_gallery--i-#{$i} {
            grid-template-columns: repeat($i, 1fr);
          }
        }
      }
    }
  }

  picture {
    display: block;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}