@mixin list--product-box{
  border: 1px solid #eee;
  gap: $space-xxs;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

@mixin list--product-box-hover{
  border: 1px solid $color-border-light;
  box-shadow: $boxshadow-hover;
  position: relative;
  transition: all 0.2s ease-in-out;
  //height: calc(100% + 80px);
  z-index: 100;

}


@mixin list--product-box-label{
  @include media-breakpoint-up('md') {
    padding-left: $space-m;
    padding-right: $space-m;
    padding-top: $space-m;
    font-size: $fontsize-m;
  }

  padding-top: $space-s;
  padding-right: $space-s;
  padding-left: $space-s;
  font-size: $fontsize-s;

  font-weight: $fontweight-bold;
  line-height: $lineheight-l;
}


@mixin list--product-box-params-list{
  font-size: $fontsize-s;
  padding-right: $space-s;
  padding-left: $space-s;

  .product-box__p-sku {
    font-size: $fontsize-xs;
    margin-top: 4px;
  }

  @include media-breakpoint-up('md'){
    padding-right: $space-m;
    padding-left: $space-m;
  }
}

@mixin list--product-box-params-list-item{

}

@mixin list--product-box-params-link{
  padding-top: $space-xxs;
  padding-bottom: $space-xxs;
  display: block;
}

@mixin list--product-box-params-label{
  font-weight: $fontweight-bold;
}

@mixin list--product-box-params-value{
  font-weight: $fontweight-base;
}

@mixin list--product-box-footer {
  transition: 0.2s ease-in-out all;
  position: relative;

}



@mixin list--product-box-footer-btn {
  background-color: $color-accent;
  color: $color-text-on-accent;
  text-align: center;
  font-size: $fontsize-s;
  font-weight: $fontweight-bold;
  padding: $space-s $space-xxl;
  display: inline-block;
  white-space: nowrap;

  &:hover {
    background-color: $color-accent-hover;
  }
}

.product-box--with-toolbar {
  position: relative;

  .product-box__toolbar {
    position: absolute;
    bottom: $space-xs;
    right: $space-xs;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out all;
  }

  &:hover {
    .product-box__toolbar {
      pointer-events: auto;
      opacity: 1;
    }
  }
}


.product-box-list.product-box-list--list.product-box-list.product-box-list--small-list {
  row-gap: 0;

  .product-box {
    @include media-breakpoint-up('md') {
      grid-template-columns: 80px auto;
      margin-top: -1px;
      gap:0;
      padding: $space-xxs;

      &__label {
        font-size: $fontsize-s;
        padding-top: $space-xxs;
      }

      &__p-list {
        font-size: $fontsize-xs;
        margin-bottom: $space-xs;
      }
    }
  }
}

.product-box-list.product-box-list--list {

  .product-box {
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: auto 1fr auto;

    &__img {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 4;
    }

    &__footer {
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
      grid-template-columns: 1fr min-content;

      &::before {
        display: none;
      }
    }

    @include media-breakpoint-up('md'){
      grid-template-columns: 200px auto;

      &__img {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 4;
      }

      &__footer {
        grid-template-columns: 1fr min-content min-content;
        padding-right: 1em;
        grid-column-end: unset;
        grid-column-start: unset;
        &:before {
          content: '';
          display: block;
        }
      }
    }


    @include list--product-box();

    &:hover {
      @include list--product-box-hover();
    }

    &__img {

      display: flex;
      width: auto;
      flex-direction: column;
      align-items: center;

      img {
        transition: all 0.2s ease-in-out;
        max-height: 100%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__label {
      display: block;
      @include list--product-box-label();
    }

    &__label-brand {
      font-size: $fontsize-xs;
      font-weight: 500;
      margin-top: 0.5rem;
    }

    &__p-list {
      @include list--product-box-params-list();
    }

    &__p-item {
      @include list--product-box-params-list-item();
    }

    &__p-link {
      @include list--product-box-params-link();
    }

    &__p-lbl {
      @include list--product-box-params-label();
    }

    &__p-val {
      @include list--product-box-params-value();
    }

    &__footer {
      font-size: $fontsize-xs;
      flex-grow: 1;
      display: grid;
      align-items: stretch;
      //padding-bottom: $space-s;

      @include list--product-box-footer();

      &:before {
        content: '';
      }
    }

    &__footer-btn {
      @include list--product-box-footer-btn();
    }

    &__footer-avl {
      font-size: $fontsize-xs;
      background-color: $color-surface-light;
      font-weight: $fontweight-bold;
      color: $color-text-lighter;
      padding: calc($space-s) $space-m;
      display: inline-block;
      white-space: nowrap;

    }

  }

}

@include media-breakpoint-up('md'){
  .product-box-list.product-box-list--list {

    .product-box {
      &__footer-avl {
          min-width: 180px;
      }

      &__footer {
        padding-bottom: $space-s;
      }
    }
  }
}