
svg path, svg circle, svg line, svg ellipse, svg rect, svg polyline {
  stroke: currentColor !important;
}

svg path:not([stroke-width]),
svg circle:not([stroke-width]),
svg line:not([stroke-width]),
svg ellipse:not([stroke-width]),
svg rect:not([stroke-width]),
svg polyline:not([stroke-width]) {
  stroke-width: 0;
}

svg[stroke-width] path:not([stroke-width]),
svg[stroke-width] circle:not([stroke-width]),
svg[stroke-width] line:not([stroke-width]),
svg[stroke-width] ellipse:not([stroke-width]),
svg[stroke-width] rect:not([stroke-width]),
svg[stroke-width] polyline:not([stroke-width]),

*[stroke-width] path:not([stroke-width]),
*[stroke-width] circle:not([stroke-width]),
*[stroke-width] line:not([stroke-width]),
*[stroke-width] ellipse:not([stroke-width]),
*[stroke-width] rect:not([stroke-width]),
*[stroke-width] polyline:not([stroke-width])
{
  stroke-width: inherit;
}


svg path[stroke="none"],
svg circle[stroke="none"],
svg line[stroke="none"],
svg ellipse[stroke="none"],
svg rect[stroke="none"],
svg polyline[stroke="none"] {
  stroke-width: 0 !important;
}

.icon {
  height: 1em;
  width: 1em;
  color: inherit;
  display: inline-block;

  &.icon-l {
    width: 1.25em;
    height: 1.25em;
    vertical-align: -.25em;
  }

  &.icon-xl {
    width: 1.5em;
    height: 1.5em;
    vertical-align: -.4em;
  }

  &.icon-xxl {
    width: 2em;
    height: 2em;
    vertical-align: -.675em;
  }
}
