.footer {
  --footer-color: #{$color-text-on-surface-dark};
  --footer-underline-color: rgba(255, 255, 255, .2);

  &.footer--light-bg {
    --footer-color: #{$color-text};
    --footer-underline-color: rgba(0, 0, 0, .2);
  }
}

.footer-text-block {
  display: flex;
  color: var(--footer-color);
  margin-bottom: $space-m;

  &__i {
    margin-right: $space-s;

    .icon {
      font-size: 24px;
    }
  }

  &__t {
    line-height: 24px;

  }

}


.footer-links {
  margin-bottom: $space-m;
  color: var(--footer-color);

  ul li {
    line-height: 24px;
  }

  a:hover {
    border-bottom: 1px solid var(--footer-underline-color);
  }
}


.footer-social {
  ul {
    display: flex;
    align-items: center;
    gap: $space-xxl;

    .icon {
      font-size: 24px;
    }
  }

  a {
    color: var(--footer-color);
  }

  margin-bottom: $space-m;

}

.footer {
  .buttons {
    margin-bottom: $space-m;
  }
}

