.title {
  .brbs {
    margin-top: 0;
  }

  &__t {
    font-weight: $fontweight-strong;
    font-size: $fontsize-xxxl;
    margin-top: $space-s;
    position: relative;
    margin-bottom: $space-xl;

    &:after {
      content: '';
      position: absolute;
      background-color: $color-accent;
      left:0;
      bottom: -20px;
      height: 4px;
      width: 65px;
    }
  }

  &__tl{
    margin-top: 2.5rem;
    margin-bottom: $space-l;
    font-size: $fontsize-m;

  }

}