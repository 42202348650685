$header-top-bar--fontsize: $fontsize-s;
$header-top-bar--items-gap: $space-l;
$header-top-bar--vert-padd: $space-s;

$header-top-bar--border-bottom: 0px;

@mixin header-top-bar {
  font-size: $header-top-bar--fontsize;
  background-color: #fafafa;
}

@mixin header-top-bar--link {

}

@mixin header-top-bar--link-hover {
  color: $color-accent;
  text-decoration: underline;
}


.header-top-bar {
  display: none;
}

@include media-breakpoint-up('lg') {

  .header-top-bar {
    display: block;
    @include header-top-bar();

    .header-nav__link {
      padding: 0;
      border:0;
      font-weight: $fontweight-base;
      font-size: $fontsize-xs;
      &:hover {
        background-color: transparent;
        color: $color-text;
      }
    }

    &__container {

    }

    &__row {

    }

    &__col-left, &__col-right {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: $header-top-bar--items-gap;
    }


    &__link {
      @include header-top-bar--link();

      &:hover {
        @include header-top-bar--link-hover();
      }

      &-icon {

      }
    }

  }

  .header-top-bar__langdropdown {
    position: relative;
    cursor: pointer;

    .header-top-bar__langdropdown-label {
      padding: $space-s $space-xs;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }

    .header-top-bar__langdropdown-links {
      z-index: 100;
      position: absolute;
      width: 100%;
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
      border-top: 1px solid $color-border;
      box-shadow: $boxshadow-hover;
      background-color: $color-surface;
      display: none;

      .header-top-bar__langdropdown-link {
        display: block;
        padding: $space-s $space-xs;

        &:hover {
          background-color: $color-surface-highlight;
          color: $color-text-on-surface-highlight;
        }
      }
    }

    &:hover {
      background-color: $color-surface-highlight;

      .header-top-bar__langdropdown-links {
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
      }

      .header-top-bar__langdropdown-links {
        display: block;
      }
    }
  }
}