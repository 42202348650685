$fontfamily-base: 'Hanken Grotesk', sans-serif;
$fontfamily-condensed: 'Hanken Grotesk', sans-serif;

$fontsize-base: 16px;

$fontsize-xxxxl: 3rem; //48px
$fontsize-xxxl: 2.5rem; // 40px
$fontsize-xxl: 2rem; //32px
$fontsize-xl: 1.5rem; //24px
$fontsize-l: 1.25rem; //20px
$fontsize-m: 1rem; //16px
$fontsize-s: 0.875rem; //14px
$fontsize-xs: 0.75rem; //12px
$fontsize-xxs: 0.6875rem; //11px

$fontweight-light: 300;
$fontweight-base: 400;
$fontweight-bold: 500;
$fontweight-strong: 700;

$font-base: $fontweight-base #{$fontsize-base}/1 #{$fontfamily-base};

$color-surface: #fff;
$color-text: #000;
$color-text-light: #333;
$color-text-lighter: #454545;
$color-text-extralight: #999;

$color-accent: #26B894;
$color-accent-hover: #1f987a;
$color-text-on-accent: #fff;


$color-accent-grayed: #8da79e;
$color-accent-grayed-hover: #7d968d;
$color-text-on-accent-grayed: #fff;

$color-surface-light: #f8f8f8;

$color-surface-highlight: #F5F5F5;
$color-text-on-surface-highlight: #000;

$color-surface-highlight-dark: #e9e9e9;
$color-text-on-surface-highlight-dark: #000;

$color-surface-highlight-hover: #eaeaea;
$color-text-on-surface-highlight-hover: #000;

$color-surface-middle-dark: #c6c6c6;
$color-text-on-surface-middle-dark: #FFFFFF;

$color-surface-dark: #161616;
$color-text-on-surface-dark: #fff;

$color-border: #b7b7b7;
$color-border-strong: #949494;
$color-border-light: #d5d5d5;
$color-border-extra-light: #e9e9e9;
$color-border-extrastrong: #575757;

$boxshadow-hover: 0px 0px 8px 0 rgba($color-border-strong, .5);

$space-xxxl: 4rem; //64px
$space-xxl: 1.75rem; //32px
$space-xl: 1.5rem; //24px
$space-l: 1.25rem; //20px
$space-m: 1rem; //16px
$space-s: 0.75rem; //12px
$space-xs: 0.5rem; //8px
$space-xxs: 0.25rem; //4px

$space-row-gap: $space-xl;

$form--color-input-border: $color-border;
$form--color-input-icon: $color-text-light;

$lineheight-base: 1.15em; //16 -> 18,4
$lineheight-l: 1.4em; //16 -> 22,4
$lineheight-xl: 1.75em; //16 -> 28
$lineheight-xxl: 2em; //16 -> 28
