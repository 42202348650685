
.categories-menu {
  --columns: 3;

  min-width: 100%;

  padding-top: 0 !important;
  background-color: $color-surface;
  border: 1px solid $color-accent-hover;

  .categories-menu__roots-list {
    columns: var(--columns);
  }

  &--multi {
    .categories-menu__roots-link {
      width: 300px;
    }
  }

  &--single {
    .categories-menu__roots-link {
      span {
        white-space: nowrap;
      }
    }
  }

  .categories-menu__roots-link {
    //width: 300px;
    padding: $space-s;
    font-weight: $fontweight-base;
    display: flex;
    font-size: $fontsize-m;
    align-items: center;
    line-height: 1.4;

    .icon {
      color: $color-accent;
      flex-shrink: 0;
      height: 1em;
      width: 1em;
      margin-right: $space-xs;
    }

    &:hover {
      background-color: $color-surface-highlight;
    }
  }

}